<template>
    <div class="infopage">
        <div class="goods_container">
            <div class="goods_item" v-for="item in orderDetails.goodsList" :key="item.pId">
                <img :src="item.goodsPic" @click="toFrom(item)"/>
                <div class="goods_item_right">
                    <span class="name on_line">{{ item.name }}</span>
                    <div class="price_count">
                        <span class="price">价格：<span style="color: #f00; font-weight: bold">¥{{ item.price }}</span></span>
                        <span class="count">x{{ item.number }}</span>
                    </div>
                </div>
            </div>
            <div class="total_price">
                <span>商品总价</span>
                <span style="min-width: 100px;"><span style="color: #aaa;">商品总价&nbsp;&nbsp;&nbsp;</span>¥{{ orderDetails.totalMoney }}</span>
            </div>
            <div class="freight" v-if="summaryOrderNo">
                <span>运费</span>
                <span style="min-width: 100px;"><span style="color: #aaa;">运费(快递)&nbsp;&nbsp;&nbsp;</span>¥{{ orderDetails.paidTicketMoney || 0 }}</span>
            </div>
            <div class="practical_price">
                <span>实付款</span>
                <span style="color: #f00;">¥{{ orderDetails.actualTotalMoney }}</span>
            </div>
        </div>
        <div class="order_info">
            <div class="order_info_box">
                <span>订单编号:</span>
                <span style="color: #aaa">{{ orderDetails.orderNo }}&nbsp;&nbsp;&nbsp;<span style="color: #000;cursor: pointer;" @click="paste(orderDetails.orderNo)">复制</span></span>
            </div>
            <div class="order_info_box">
                <span>备注:&nbsp;&nbsp;</span>
                <span>{{ orderDetails.remarks }}</span>
            </div>
            <div class="order_info_box">
                <span>创建时间:</span>
                <span style="color: #aaa">{{ orderDetails.createDate }}</span>
            </div>
            <div class="order_info_box">
                <span>交付时间:</span>
                <span style="color: #aaa">{{ orderDetails.deliverDay }}</span>
            </div>
            <div class="order_info_box" v-if="!summaryOrderNo">
                <span>交付地址:</span>
                <span style="color: #aaa">{{ orderDetails.deliverAddr }}</span>
            </div>
        </div>
        <footer-com></footer-com>
    </div>
</template>

<script>
export default {
    name: 'InfoPage',
    data() {
        return {
            summaryOrderNo: null,//表示是否完成支付
            orderDetails: {},//订单详情
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const loading = this.$loading({
                lock: true,
                text: "请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await this.$api.getInfo({
                id: this.$route.query.id,
                orderNumber: this.$route.query.orderNumber,
            })
            loading.close()
            if (res && res.code == 200) {
                this.orderDetails = res.data
            } else {
                this.$message.error('网络失败请刷新重试')
            }
        },

        //复制订单编号
        paste(value) {
            navigator.clipboard.writeText(value)
            this.$message.success("复制成功!")
        },

        toFrom(item) {
            const openRoute = this.$router.resolve({
                name: "ArticlesDetail",
                params: {
                    goodsId: item.goodsMainId,
                },
            });
            window.open(openRoute.href, "_blank");
        },
    }
}
</script>

<style lang="scss">
.infopage {
    font-size: max(14px,1vw);

    .goods_container {
        width: max(300px, 80%);
        margin:3% auto;
        .goods_item {
            border-radius: 0.8vw;
            padding: 1%;
            border: 2px solid #eee;
            margin-bottom: 1%;
            display: flex;
            >img {
                width: 10vw;
                height: 8vw;
                object-fit: cover;
                border-radius: 0.8vw;
                margin-right: 2%;
                cursor: pointer;
            }

            .goods_item_right {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .price_count {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .count {
                        color: #aaa;
                    }
                }
            }
        }
        .total_price,.freight,.practical_price {
            padding: 1% 0%;
            display: flex;
            justify-content: space-between;
        }
    }

    .order_info {
        width: max(300px, 80%);
        margin:3% auto;
        border-radius: 0.8vw;
        background-color: #fff;
        margin-top: 1%;

        .order_info_box {
            display: flex;
            justify-content: space-between;
            margin-top: 2%;
            flex-wrap: wrap;
            >span:nth-child(1){
                white-space: nowrap;
            }
        }
    }

}
</style>